import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import chunk from "lodash/chunk";
import React, { useState } from "react";

import {
  Box,
  Divider,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  UnderlineButton,
} from "flicket-ui";
import { useRouter } from "next/router";
import { css, DefaultTheme, useTheme } from "styled-components";
import { appTheme, Status } from "~components";
import {
  EventsList,
  useEventsService,
} from "~features/events/hooks/useEventsService";
import CustomModal from "~components/CustomModal";
import { removeQueryParams } from "~lib/helpers/queryParams";
import omit from "lodash/omit";
import { organization } from "~test-setup/data";
import { useOrganization } from "~hooks";
import { getLightOrDarkColor } from "~lib";
import { getThemeValue } from "flicket-ui/build/lib";

interface CalendarProps {
  events: EventsList;
  onSelectEvent: (eventId: string) => Promise<void> | void;
  currentEventId: string;
}

const Calendar: React.FC<CalendarProps> = ({
  events,
  onSelectEvent,
  currentEventId,
}) => {
  const eventsByDate = events?.reduce(
    (acc: { [key: string]: EventsList }, event) => {
      const dateKey = format(
        new Date(
          event?.dates[0]?.startDate ? event?.dates[0]?.startDate : null
        ),
        "yyyy-MM-dd"
      );
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(event);
      return acc;
    },
    {}
  );

  const theme = useTheme();

  const firstEventDate = events?.sort(
    (a, b) =>
      new Date(a.dates[0].startDate).getTime() -
      new Date(b.dates[0].startDate).getTime()
  )[0].startDate;

  const selectedEventDate = events.find((event) => event.id === currentEventId)
    ?.startDate;

  const [currentMonth, setCurrentMonth] = useState(new Date(firstEventDate));
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    selectedEventDate ? new Date(selectedEventDate) : undefined
  );
  const [dayClicked, setDayClicked] = useState<string>();

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
  const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

  const daysInMonthGrid = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  const onDateClick = (date: Date) => {
    setSelectedDate(date);
    void onSelectEvent(eventsByDate[format(date, "yyyy-MM-dd")][0].id);
  };

  const nextMonth = () => {
    setSelectedDate(null);
    setCurrentMonth(addMonths(currentMonth, 1));
  };
  const prevMonth = () => {
    setSelectedDate(null);
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  return (
    <Box minHeight={410} px={{ md: 5 }}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        mb={5}
        px={{ md: "54px" as any }}
      >
        <UnderlineButton onClick={prevMonth} color="N800">
          <Text variant="regular">Prev</Text>
        </UnderlineButton>

        {/* <AnimatePresence exitBeforeEnter> */}
        {/* <TransitionAnimation key={currentMonth.toString()}> */}
        <Text as="h2" variant="header.M">
          {format(currentMonth, "MMMM yyyy")}
        </Text>
        {/* </TransitionAnimation> */}
        {/* </AnimatePresence> */}

        <UnderlineButton onClick={nextMonth} color="N800">
          <Text variant="regular">Next</Text>
        </UnderlineButton>
      </Stack>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "4px",
          marginBottom: "1rem",
        }}
      >
        {weekdays.map((day) => (
          <Text key={day} textAlign="center" variant="regular">
            {day}
          </Text>
        ))}
      </div>
      {chunk(daysInMonthGrid, 7).map((week, weekIndex) => (
        <div key={weekIndex} style={{ marginBottom: "1rem" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            {week.map((day) => {
              const dateKey = format(day, "yyyy-MM-dd");
              const eventsOnDate = eventsByDate
                ? eventsByDate[dateKey] || []
                : [];
              const isSelectedDate =
                selectedDate && format(selectedDate, "yyyy-MM-dd") === dateKey;
              const isInCurrentMonth = monthStart <= day && day <= monthEnd;
              const isClickable = isInCurrentMonth && eventsOnDate.length > 0;

              const isClicked = dayClicked === dateKey;
              const backgroundColor =
                isSelectedDate && !isClicked ? "P300" : "white";
              const textColor =
                isSelectedDate && !isClicked
                  ? getLightOrDarkColor({
                      background: theme.colors[backgroundColor],
                      dark: theme.colors.N800,
                    })
                  : !isClickable
                  ? "N300"
                  : "N800";

              return isInCurrentMonth ? (
                <Box
                  key={dateKey}
                  css={
                    !isSelectedDate &&
                    isClickable &&
                    !isClicked &&
                    css(
                      (p) =>
                        `
                        &:hover {
                          background-color: ${p.theme.colors.N200};
                        }
                      `
                    )
                  }
                  border={!isClicked && "1px solid"}
                  borderColor={
                    isSelectedDate ? "P300" : isClickable ? "N600" : "white"
                  }
                  backgroundColor={backgroundColor}
                  style={{
                    textAlign: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50%", // Change this to create circular elements
                    width: "40px", // Set a fixed width and height
                    height: "40px",
                    padding: "4px",
                    justifyContent: "center",
                    cursor: isClickable ? "pointer" : "not-allowed",
                    fontWeight: isClickable ? "bold" : "normal",
                    margin: "auto", // Add this line to center the circles in the grid cells
                  }}
                  onClick={
                    isClickable
                      ? () => {
                          setDayClicked(dateKey);
                          // Delay for loader state to update
                          setTimeout(() => onDateClick(day), 10);
                        }
                      : undefined
                  }
                >
                  <Text
                    position="relative"
                    variant="regular"
                    fontWeight={isClickable ? "bold" : undefined}
                    color={textColor as any}
                  >
                    {isClicked && (
                      <Spinner
                        size={50}
                        position="absolute"
                        css={`
                        top: 50%;
                        left 50%;
                          transform: translateY(-50%) translateX(-50%);
                          > div > div {
                            border-size: 1px !important;
                            border-color: #8e8e8e transparent transparent transparent;
                          }
                        `}
                      />
                    )}
                    {format(day, "dd")}
                  </Text>
                </Box>
              ) : (
                <div key={dateKey} />
              );
            })}
          </div>
        </div>
      ))}
    </Box>
  );
};

const EventsCalendarModal: React.FC<{
  isOpen: boolean;
  onClose?: () => void;
}> = ({ isOpen, onClose }) => {
  const router = useRouter();
  function close() {
    void removeQueryParams(router, ["showCalendar"]);
    onClose();
  }

  async function handleSelectEvent(eventId: string) {
    await router.replace(
      {
        pathname: router.pathname,
        query: {
          ...omit(router.query, "showCalendar"),
          eventId: eventId,
        },
      },
      undefined,
      { shallow: true }
    );
    onClose();
  }

  return (
    <CustomModal
      isOpen={isOpen || !!router.query.showCalendar}
      close={close}
      clickOutsideToClose={true}
    >
      <CustomModal.Header>Event Calendar</CustomModal.Header>
      <CustomModal.Content>
        <EventsCalendar handleSelectEvent={handleSelectEvent} />
      </CustomModal.Content>
    </CustomModal>
  );
};

export default EventsCalendarModal;

export function EventsCalendar({
  handleSelectEvent,
}: {
  handleSelectEvent: CalendarProps["onSelectEvent"];
}) {
  const router = useRouter();
  const { organization, hasFeature } = useOrganization();

  const { events, error, isLoading } = useEventsService({
    includeFeaturedEvents: true,
  });

  const isPointsEnabled = hasFeature("points") && organization.point?.isEnabled;

  return (
    <Box minHeight={"470px"}>
      <Status loading={isLoading} error={error}>
        <Box minHeight={"450px"}>
          <Calendar
            events={events}
            onSelectEvent={handleSelectEvent}
            currentEventId={router.query.eventId as string}
          />
          {organization.showMemberships && (
            <PrimaryButton
              to={`memberships`}
              width={{ _: "100%" }}
              mb={2}
              alignContent={{ _: "center", md: "flex-start" }}
            >
              Click here for multi-day passes
            </PrimaryButton>
          )}
        </Box>

        <Divider mb={2} />

        {isPointsEnabled && (
          <Box>
            <Text variant="regular">
              Not sure when to attend? Buy{" "}
              <UnderlineButton to={`/memberships`} color="N800">
                <Text variant="regular">{organization.point.name}</Text>
              </UnderlineButton>
            </Text>
          </Box>
        )}
      </Status>
    </Box>
  );
}
